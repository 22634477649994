<template>
  <div>
    <el-card shadow="never">
      <div class="flex ju-end mb-32">
        <el-button type="primary" @click="saveForm()">保存</el-button>
      </div>
      <div>
        <el-form ref="ruleForm" label-width="120px">
          <el-form-item label="标题" prop="title">
            <el-input
              v-model="loadData.title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="电子样册分类">
            <div class="flex">
              <el-select v-model="photoAlbumSortId" placeholder="请选择">
                <el-option
                  v-for="(item, index) in bookClassifyList"
                  :key="index"
                  :label="item.sortName"
                  :value="item.photoAlbumSortId"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="参考价格" prop="title">
            <el-input
              v-model="loadData.price"
              placeholder="请输入参考价格"
            ></el-input>
          </el-form-item>
          <el-form-item label="封面" prop="coverImg">
            <el-upload
              :class="{
                disabled: uploadDisabled,
              }"
              ref="uploadIcon"
              :action="$store.state.uploadingUrl"
              list-type="picture-card"
              :on-success="handleAvatarSuccessCoverImg"
              :on-remove="handleRemove"
              :file-list="fileListCover"
              :limit="1"
              accept=".png,.jpg"
              :data="uploadData"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                *格式png、jpg，大小不超过2MB。
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="绑定图壳应用">
            <div class="flex">
              <el-select
                v-model="loadData.tookType"
                placeholder="请选择平台类型"
                @change="changeExploreType"
                style="width: 200px"
              >
                <el-option
                  :disabled="item.id == 0 ? false : true"
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                class="ml-6"
                v-model="loadData.selectClass"
                placeholder="请选择分类"
                @change="changeClassfiy"
              >
                <el-option
                  v-for="item in classfiyList"
                  :key="item.typeId"
                  :label="item.labelName"
                  :value="item.typeId"
                >
                </el-option>
              </el-select>
              <el-select
                class="ml-6"
                v-model="exploreId"
                placeholder="请选择内容"
                @change="changeModel"
              >
                <el-option
                  v-for="(item, index) in modelList"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                >
                  {{ item.title }}
                </el-option>
              </el-select>
              <div class="ml-6" v-if="exploreId && loadData.content">
                <a
                  style="background: #35455b; display: block"
                  class="text-center co-ff w-100 h-33 ra-4"
                  target="_blank"
                  :href="loadData.content"
                  >预览</a
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item label="绑定短视频">
            <div class="flex">
              <el-select v-model="videoId" placeholder="请选择内容">
                <el-option
                  v-for="(item, index) in videoList"
                  :key="index"
                  :label="item.title"
                  :value="item.videoId"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="xian mtb-16"></div>
      <div class="flex ju-be">
        <!-- <el-upload
          :action="uploadUrl"
          class="upload-demo"
          :on-success="handleSuccess"
          :file-list="fileList"
          :show-file-list="false"
          :limit="9"
          :data="uploadData"
        > -->
        <el-upload
          :action="uploadUrl"
          class="upload-demo"
          :on-success="handleSuccess"
          :file-list="fileList"
          :show-file-list="false"
          :data="uploadData"
        >
          <el-button type="primary">上传图片</el-button>
          <div slot="tip" class="el-upload__tip">
              *格式png、jpg，大小不超过2MB。
            </div>
        </el-upload>
      </div>
      <el-table
        class="mt-16"
        :data="fileList"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column align="center" label="图片">
          <div slot-scope="scope">
            <img class="w-50 h-50 ra-4" :src="scope.row.url" alt="" />
          </div>
        </el-table-column>
        <el-table-column align="center" label="标题">
          <div slot-scope="scope">
            <el-input
              style="width: 200px"
              v-model="scope.row.title"
              placeholder="请输入"
            ></el-input>
          </div>
        </el-table-column>
        <el-table-column sortable align="center" prop="address" label="排序">
          <div slot-scope="scope">
            <el-input
              type="text"
              v-model="scope.row.sort"
              placeholder="数值越大，顺序越靠前"
              show-word-limit
              style="width: 200px"
              @blur="sortSubmit"
            >
            </el-input>
          </div>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <div slot-scope="scope">
            <el-button type="text" @click="deleteTab(scope.row)"
              >删除</el-button
            >
          </div>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { albumAddAlbum, albumUpdateAlbum } from "@/api/electronBook";
import { photoAlbumSortQuery } from "@/api/electronBook";
// 短视频
import { queryPage } from "@/api/videoManage";
// 图壳应用
import { blendQueryPage, typeQueryPage } from "@/api/drawingBed";
export default {
  data() {
    return {
      uploadData: {
        icon: true,
        preview: true,
        card:true
      },
      loadData: {},
      fileList: [],
      fileListCover: [],
      uploadUrl: this.$store.state.uploadingUrl,
      videoList: [],
      typeList: [
        {
          title: "图壳应用",
          id: 0,
        },
        {
          title: "图壳模型",
          id: 1,
        },
        {
          title: "近迁全景",
          id: 2,
        },
      ],
      classfiyList: [],
      videoId: "",
      exploreId: "",
      modelList: [],
      bookClassifyList: [],
      photoAlbumSortId: "",
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileListCover.length !== 0;
    },
  },
  created() {
    console.log(this.$route.params.item,'999');
    if (this.$route.params.item) {
      this.loadData = this.$route.params.item;
      this.fileListCover = [
        {
          url: this.loadData.cover,
          name: "img",
        },
      ];
      if (this.loadData.photoAlbum) {
        console.log(JSON.parse(this.loadData.photoAlbum),'图片');
        this.fileList = JSON.parse(this.loadData.photoAlbum);
        // this.fileList = this.loadData.photoAlbum;
        let arr = this.loadData.photoAlbum;
        console.log(this.fileList, "图片数据");
      }
      if (this.loadData.isThirdBoard) {
        this.getClassfiyList();
        this.getapplyList();
        this.exploreId = this.loadData.isThirdBoard;
        this.videoId = this.loadData.videoId;
      }
      this.photoAlbumSortId = this.loadData.photoAlbumSortId || "";
    }
    this.getVideo();
    this.getBookClassifyList();
  },
  methods: {
    getBookClassifyList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
      };
      photoAlbumSortQuery(data).then((res) => {
        this.bookClassifyList = res.data.list;
      });
    },
    // 图壳
    changeExploreType(e) {
      if (e == 0) {
        this.getClassfiyList();
      }
    },
    // 获取分类列表
    getClassfiyList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
      };
      typeQueryPage(data).then((res) => {
        this.classfiyList = res.data.records;
      });
    },
    changeClassfiy(e) {
      this.loadData.isThirdBoard = "";
      this.loadData.content = "";
      this.getapplyList(e);
    },
    // 获取图壳内容
    getapplyList(id) {
      let data = {
        pageNum: 1,
        pageSize: 100,
        typeId: id,
      };
      blendQueryPage(data).then((res) => {
        this.modelList = res.data.records;
      });
    },
    changeModel(e) {
      this.loadData.isThirdBoard = e;
      this.modelList.forEach((el) => {
        if (el.id == e) {
          this.loadData.content = el.realUrl;
        }
      });
    },
    // 获取短视频
    getVideo() {
      let data = {
        pageSize: 100,
        pageNum: 1,
        queryType: "",
      };
      queryPage(data).then((res) => {
        if (res.data.pageInfo.list) {
          this.videoList = res.data.pageInfo.list;
        }
      });
    },
    saveForm() {
      this.loadData.cid = localStorage.getItem("companyId");
      if (!this.loadData.title) {
        this.$message.warning("请输入标题！");
        return;
      }
      if (!this.loadData.cover) {
        this.$message.warning("请上传封面！");
        return;
      }
      if (this.fileList.length < 1) {
        this.$message.warning("请最少上传一个样册！");
        return;
      }
      this.loadData.photoAlbum = JSON.stringify(this.fileList);
      this.loadData.videoId = this.videoId || "";
      this.loadData.photoAlbumSortId = this.photoAlbumSortId || "";
      console.log(this.loadData, "添加样册");
      if (this.loadData.aid) {
        albumUpdateAlbum(this.loadData).then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功！");
            this.dialogVisible = false;
            this.$router.go(-1);
          }
        });
      } else {
        albumAddAlbum(this.loadData).then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功！");
            this.dialogVisible = false;
            this.$router.go(-1);
          }
        });
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    handleSuccess(res, file, fileList) {
      this.$message.closeAll();
      this.isLoading = false;
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.fileList.push({
        url: `https://cdn-oss.jquen.com/${res.data}`,
        title: "",
        sort: 0,
      });
      console.log(this.fileList);
    },
    sortSubmit() {
      let arr = JSON.parse(JSON.stringify(this.fileList));
      function fuch(a, b) {
        return b.sort - a.sort;
      }
      this.fileList = arr.sort(fuch);
      // console.log(arr.sort(fuch),'arr----------');
    },
    deleteTab(item) {
      this.fileList.splice(item.index, 1);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.loadData.cover = "";
      this.fileListCover = [];
    },
    // 封面
    handleAvatarSuccessCoverImg(res, file, fileList) {
      this.fileListCover = fileList;
      console.log(this.fileListCover, "fileList");
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.loadData.cover = `https://cdn-oss.jquen.com/${res.data}`;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep.upload-demo {
  .el-upload {
    width: auto;
    height: auto;
    border: none;
    border-radius: 2px;
  }
}

::v-deep .disabled .el-upload--picture-card {
  display: none;
}
</style>
